import React, {lazy} from 'react';
import {
    administrationAccount,
    administrationSite,
    appManagement,
    dashboardMenu,
    demoPages,
    matchManagements, matchStatisticsManage
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import Register from "../pages/presentation/auth/Register";
import ForgottenPassword from "../pages/presentation/auth/ForgottenPassword";
import VerifyAccount from "../pages/presentation/auth/VerifyAccount";


const LANDING = {
    DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
    YOUTUBE_LIVE: lazy(() => import('../pages/dashboard/YoutubeLivePage')),
    USERS: lazy(() => import('../pages/dashboard/app-administration/Users')),
    TRANSFERS: lazy(() => import('../pages/dashboard/app-administration/Transfers')),
    TEAMS: lazy(() => import('../pages/dashboard/app-administration/Teams')),
    COMPETITIONS: lazy(() => import('../pages/dashboard/app-administration/competitions/Competitions')),
    CUP_MANAGEMENT: lazy(() => import('../pages/dashboard/app-administration/cup/CupManagement')),
    TOUR_MANAGEMENT: lazy(() => import('../pages/dashboard/app-administration/cup/TourManagement')),
    TOUR_ADMINISTRATION: lazy(() => import('../pages/dashboard/app-administration/cup/TourAdministration')),
    EDITIONS: lazy(() => import('../pages/dashboard/app-administration/competitions/Editions')),
    EDITION_JOURNAL: lazy(() => import('../pages/dashboard/app-administration/edition-journal/EditionJournal')),
    LEAGUES: lazy(() => import('../pages/dashboard/app-administration/competitions/Leagues')),
    ADMINISTRATE_LEAGUE: lazy(() => import('../pages/dashboard/app-administration/competitions/AdministrateLeague')),
    DISCIPLINARY: lazy(() => import('../pages/dashboard/app-administration/Disciplinary')),
    MATCH_STATISTICS: lazy(() => import('../pages/dashboard/app-administration/match-statistics/MatchStatistics')),
    GENERAL_SETTINGS: lazy(() => import('../pages/dashboard/app-administration/GeneralSettings')),
    FINANCIAL: lazy(() => import('../pages/dashboard/app-administration/Financial')),
    MATCH_EVENTS: lazy(() => import('../pages/dashboard/app-administration/match-events/MatchEvents')),
    MATCH_EVENT_DETAILS: lazy(() => import('../pages/dashboard/app-administration/match-events/MatchEventsDetails')),
    LINEUP: lazy(() => import('../pages/dashboard/app-administration/match-events/Lineup')),
    NEWS: lazy(() => import('../pages/dashboard/website/media/News')),
    CHRONICLES: lazy(() => import('../pages/dashboard/website/media/Chronicles')),
    MATCHES_MEDIA: lazy(() => import('../pages/dashboard/website/media/Matches')),
    PARTNERS: lazy(() => import('../pages/dashboard/website/media/Partners')),
    AWARDS: lazy(() => import('../pages/dashboard/website/media/Awards')),
    WINNER_TEAMS: lazy(() => import('../pages/dashboard/website/media/WinnerTeams')),
    LEGENDS: lazy(() => import('../pages/dashboard/website/media/Legends')),
    PARTNERS_CATEGORIES: lazy(() => import('../pages/dashboard/website/media/PartnersCategory')),
    MENUS_CONFIG: lazy(() => import('../pages/dashboard/website/menus/MenusConfiguration')),
    MENU_ITEMS: lazy(() => import('../pages/dashboard/website/menus/MenuItems')),
    DOCS_ADMINISTRATION: lazy(() => import('../pages/dashboard/app-administration/docs-administration/DocsAdministration')),
    FAQ: lazy(() => import('../pages/dashboard/app-administration/faq/Faq')),
    FAQ_CATEGORY: lazy(() => import('../pages/dashboard/app-administration/faq/FaqCategory')),

};

const ACCOUNT = {
    SETTINGS: lazy(() => import('../pages/dashboard/management-account/AccountSettings')),
    YOUR_DOCUMENTS: lazy(() => import('../pages/dashboard/management-account/YourDocuments')),
    YOUR_TEAMS: lazy(() => import('../pages/dashboard/management-account/YourTeams')),
    YOUR_PAYMENTS: lazy(() => import('../pages/dashboard/management-account/YourPayments')),
    ADD_PLAYERS: lazy(() => import('../pages/dashboard/management-account/AddPlayers')),
    YOUR_TRANSFERS: lazy(() => import('../pages/dashboard/management-account/YourTransfers')),
    TEAM_LOT: lazy(() => import('../pages/dashboard/management-account/TeamLot')),
    CARDS_SUSPENDS: lazy(() => import('../pages/dashboard/management-account/CardsAndSuspends')),
}

const AUTH = {
    PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


const presentation = [
    /**
     * Landing
     */
    {
        path: dashboardMenu.dashboard.path,
        element: <LANDING.DASHBOARD/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: dashboardMenu.youtube.path,
        element: <LANDING.YOUTUBE_LIVE/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: demoPages.page404.path,
        element: <AUTH.PAGE_404/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: demoPages.login.path,
        element: <Login/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: demoPages.resetPassword.path,
        element: <ForgottenPassword/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: demoPages.register.path,
        element: <Register/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: demoPages.verifyAccount.path,
        element: <VerifyAccount/>,
        exact: true,
        roles: ['ALL']
    },

    /**
     * App administration Page
     */

    {
        path: appManagement.users.path,
        element: <LANDING.USERS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: appManagement.transfers.path,
        element: <LANDING.TRANSFERS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: appManagement.footballTeams.path,
        element: <LANDING.TEAMS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: appManagement.competitions.path,
        element: <LANDING.COMPETITIONS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: appManagement.cupManagement.path,
        element: <LANDING.CUP_MANAGEMENT/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: appManagement.editionJournal.path,
        element: <LANDING.EDITION_JOURNAL/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: appManagement.docs.path,
        element: <LANDING.DOCS_ADMINISTRATION />,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/competitions/editions',
        element: <LANDING.EDITIONS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/competitions/editions/leagues',
        element: <LANDING.LEAGUES/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/competitions/editions/leagues/administrate',
        element: <LANDING.ADMINISTRATE_LEAGUE/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/cup/tour-management',
        element: <LANDING.TOUR_MANAGEMENT/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/cup/tour-administration',
        element: <LANDING.TOUR_ADMINISTRATION/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/general-settings',
        element: <LANDING.GENERAL_SETTINGS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    {
        path: '/management/financial',
        element: <LANDING.FINANCIAL/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },

    {
        path: '/management/disciplinary',
        element: <LANDING.DISCIPLINARY/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ORGANIZER']
    },
    {
        path: matchStatisticsManage.matchStatistics.path,
        element: <LANDING.MATCH_STATISTICS/>,
        exact: true,
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ORGANIZER']
    },

    /**
     * Manage Match Events
     */

    {
        path: matchManagements.matchEvents.path,
        element: <LANDING.MATCH_EVENTS/>,
        exact: true,
        roles: ['ROLE_ADMIN']
    },
    {
        path: '/match-management/events/match-details',
        element: <LANDING.MATCH_EVENT_DETAILS/>,
        exact: true,
        roles: ['ALL']
    },
    {
        path: '/match-management/events/lineup',
        element: <LANDING.LINEUP/>,
        exact: true,
        roles: ['ALL']
    },

    /**
     * Account Management Page
     */

    {
        path: administrationAccount.account.path,
        element: <ACCOUNT.SETTINGS/>,
        exact: true,
        roles: ['ROLE_MANAGER']
    },

    {
        path: administrationAccount.teams.path,
        element: <ACCOUNT.YOUR_TEAMS/>,
        exact: true,
        roles: ['ROLE_MANAGER']
    },

    {
        path: administrationAccount.accountDocuments.path,
        element: <ACCOUNT.YOUR_DOCUMENTS/>,
        exact: true,
        roles: ['ROLE_MANAGER']
    },

    {
        path: administrationAccount.players.path,
        element: <ACCOUNT.ADD_PLAYERS/>,
        exact: true,
        roles: ['ROLE_MANAGER']
    },

    {
        path: administrationAccount.transfers.path,
        element: <ACCOUNT.YOUR_TRANSFERS/>,
        exact: true,
        roles: ['ROLE_MANAGER']
    },

    {
        path: administrationAccount.teamLot.path,
        element: <ACCOUNT.TEAM_LOT/>,
        exact: true,
        roles: ['ROLE_MANAGER']
    },
    {
        path: administrationAccount.manageDisciplinary.path,
        element: <ACCOUNT.CARDS_SUSPENDS />,
        exact: true,
        roles: ['ROLE_MANAGER'],
    },

    {
        path: administrationAccount.financial.path,
        element: <ACCOUNT.YOUR_PAYMENTS/>,
        exact: true,
        roles: ['ROLE_MANAGER', 'ROLE_DELEGATE']
    },
    /** ************************************************** */

    /**
     * Site Administration
     */

    {
        path: administrationSite.media.subMenu.news.path,
        element: <LANDING.NEWS/>,
        exact: true,
        roles: ['ROLE_ADMIN']
    },
    {
        path: administrationSite.media.subMenu.chronicles.path,
        element: <LANDING.CHRONICLES/>,
        exact: true,
        roles: ['ROLE_ADMIN']
    },
    {
        path: administrationSite.media.subMenu.matches.path,
        element: <LANDING.MATCHES_MEDIA/>,
        exact: true,
        roles: ['ROLE_ADMIN']
    },
    {
        path: administrationSite.media.subMenu.partners.path,
        element: <LANDING.PARTNERS />,
        exact: true,
        roles: ['ROLE_ADMIN'],
    },
    {
        path: administrationSite.media.subMenu.awards.path,
        element: <LANDING.AWARDS />,
        exact: true,
        roles: ['ROLE_ADMIN'],
    },
    {
        path: administrationSite.media.subMenu.legends.path,
        element: <LANDING.LEGENDS />,
        exact: true,
        roles: ['ROLE_ADMIN'],
    },
    {
        path: administrationSite.media.subMenu.winnerTeams.path,
        element: <LANDING.WINNER_TEAMS />,
        exact: true,
        roles: ['ROLE_ADMIN'],
    },
    {
        path: administrationSite.questions.path,
        element: <LANDING.FAQ />,
        exact: true,
        roles: ['ROLE_ADMIN']
    },
    {
        path: '/questions/categories',
        element: <LANDING.FAQ_CATEGORY />,
        exact: true,
        roles: ['ROLE_ADMIN']
    },
    {
        path: '/media/partners-categories',
        element: <LANDING.PARTNERS_CATEGORIES />,
        exact: true,
        roles: ['ROLE_ADMIN'],
    },
    {
      path: administrationSite.menus.path,
      element: <LANDING.MENUS_CONFIG />,
      exact: true,
      roles: ['ROLE_ADMIN']
    },
    {
        path: '/menus/items',
        element: <LANDING.MENU_ITEMS />,
        exact: true,
        roles: ['ROLE_ADMIN']
    },

];
const contents = [...presentation];

export default contents;
